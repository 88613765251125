import React from 'react';

class Mean extends React.Component{
  constructor(props){
    super(props);
    this.state={
      count: 7,
      scope: 100,
      average: 350,
      items: []
    };
  }

  handleChange(e,vname){
    switch(vname) {
      case 'average': this.setState({average: Number(e.target.value)}, () => this.exec()); break;
      case 'count': this.setState({count: Number(e.target.value)}, () => this.exec()); break;
      case 'scope': this.setState({scope: Number(e.target.value)}, () => this.exec()); break;
      default: return;
    }
  }

  exec(e){
    const lower=this.state.average-this.state.scope;
    const upper=this.state.average+this.state.scope;
    const items=this.props.findItems(this.state.average, this.state.count, lower, upper);
    this.setState({
      lower: lower,
      upper: upper,
      items: items
    });
  }

  componentDidMount(){
    this.exec();
  }

  render(){
    return <div className="row">
      <div className="input col-12 col-md-6">
        <div className="input-group mb-3">
          <span className="input-group-text" id="average">Average</span>
          <input type="number" onChange={(e) => this.handleChange(e, 'average')} value={this.state.average} className="form-control" placeholder="average" aria-label="average" aria-describedby="average"/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="count">Count</span>
          <input type="number" onChange={(e) => this.handleChange(e, 'count')} value={this.state.count} className="form-control" placeholder="items count" aria-label="count" aria-describedby="count"/>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text" id="scope">Scope</span>
          <input type="number" onChange={(e) => this.handleChange(e, 'scope')} value={this.state.scope} className="form-control" placeholder="scope" aria-label="scope" aria-describedby="scope"/>
        </div>
        <button className="btn btn-large btn-success" onClick={(e) => this.exec(e)}>Calc</button>
      </div>
      <div className="output col-12 col-md-6">
        <div>Limits from <b>{this.state.lower}</b> to <b>{this.state.upper}</b></div>
        <div>
        <ol>
        {
          this.state.items.map((val, key) => <li key={key}>{val}</li>)
        }
        </ol>

        Control mean check: {this.props.calcMean(this.state.items)}
        </div>
      </div>
    </div>;
  }
}

export default Mean;
