import React, { useState } from 'react';

function Table(props) {
  const [rows, setRows] = useState(4);
  const [cols, setCols] = useState(2);
  const [means, setMeans] = useState([50,30,15,5]);
  const [spreads, setSpreads] = useState([5,5,5,3]);
  const [data, setData] = useState([
    [1,1],
    [2,2],
    [3,3],
    [4,4]
  ]);

  /* change array with row means */
  function handleMeanChange(row, value) {
    console.clear();
    let newMeans=means.slice();
    newMeans[row]=Number(value);
    setMeans(newMeans);
    console.log(`Row ${row}, mean ${value}`);
    console.log(newMeans);
    console.log(means);
    exec(data, newMeans, spreads);
  }

  /* change array with row spreads */
  function handleSpreadChange(row, value) {
    console.clear();
    let newSpreads=spreads.slice();
    newSpreads[row]=Number(value);
    setSpreads(newSpreads);
    console.log(`Row ${row}, spread ${value}`);
    console.log(newSpreads);
    console.log(spreads);
    exec(data, means, newSpreads);
  }

  /* recreate and refill the matrix with dummies after cols or rows amounts were changed */
  function handleMatrixChange(rows, cols) {
    console.log(`Rows ${rows}, cols ${cols}`);
    if (rows<0) rows=0;
    if (cols<0) cols=0;

    const rowsLimit= rows>=1 ? rows : 1;
    const colsLimit= cols>=1 ? cols : 1;

    var data=[];
    var row=[];
    var means=[];
    var spreads=[];
    var i, j;
    for (i=1; i<=rowsLimit; i++) {
      row=[];
      for (j=1; j<=colsLimit; j++) {
        row.push(1);
      }
      data.push(row);
      means.push(Math.floor(Math.random() * 100 + 1));
      spreads.push(5);
    }
    setRows(rows);
    setCols(cols);
    setMeans(means);
    setSpreads(spreads);
    exec(data, means, spreads);
  }

  function exec(data, means, spreads) {
    console.clear();
    console.log('Data');
    console.log(data);
    console.log('Means');
    console.log(means);
    console.log('Spreads');
    console.log(spreads);
    /* fill all row with random items according to row's mean, except last row */
    let i;
    let items=[];
    for (i=0; i<data.length-1; i++) {
      data[i]=props.findItems(
        means[i],
        data[i].length,
        means[i]-spreads[i],
        means[i]+spreads[i]
      );
    }

    /* START calculate last row: change last of each col value to make col's summary of 100 */
    console.clear();

    /* cut off last row and transpone matrix */
    let arr = data.slice();
    arr.pop();
    let transponed = transpone(arr);

    const meansSum = sumArr(means);
    transponed.map((col, index) => {
      col.push(meansSum - sumArr(col));
    });

    arr = transpone(transponed);
    console.log(arr);
    setData(arr);
    return data;
  }

  function transpone(arr) {
    return arr[0].map((col, i) =>
      arr.map(row => row[i])
    );
  }

  function sumArr(arr) {
    return arr.reduce((a, b) => a + b, 0);
  }

  return <div>
  <div className="input-group mb-1">
    <span className="input-group-text" id="count">Rows</span>
    <input type="number" onChange={e => handleMatrixChange(e.target.value, cols)} value={rows} className="form-control" placeholder="rows" aria-label="count" aria-describedby="count"/>
  </div>
  <div className="input-group mb-1">
    <span className="input-group-text" id="count">Cols</span>
    <input type="number" onChange={e => handleMatrixChange(rows, e.target.value)} value={cols} className="form-control" placeholder="columns" aria-label="count" aria-describedby="count"/>
  </div>

  <table className="table table-hover">
    <thead>
      <tr>
        {
          data[0].map((val, index) => <th key={`thead_${index}`}>{index+1}</th>)
        }
        <th/>
      </tr>
    </thead>
    <tbody>
    {
      data.map((row, rIndex) => <tr key={`row_${rIndex}`} className="align-middle">
        {
          row.map((field, fIndex) => <td key={`field_${rIndex}_${fIndex}`}>{field}</td>)
        }
        <td>
          <div className="input-group input-group-sm">
            <span className="input-group-text">Mean&nbsp;&nbsp;</span>
            <input type="number" value={means[rIndex]} onChange={e => handleMeanChange(rIndex, e.target.value)} className="form-control" />
          </div>
          {
            rIndex!==data.length-1 &&
            <div className="input-group input-group-sm">
              <span className="input-group-text">Spread</span>
              <input type="number" value={spreads[rIndex]} onChange={e => handleSpreadChange(rIndex, e.target.value)} className="form-control" />
            </div>
          }
        </td>
      </tr>)
    }
    </tbody>
  </table>

  <button className="btn btn-success" onClick={() => exec(data, means, spreads)}>Recalc</button>

  </div>;
}

export default Table;
